<template>
  <div>
    <el-divider content-position="left" class="tittle"
      >Operator List</el-divider
    >
    <!-- 账号列表 -->
    <el-table :data="tableData" style="width: 100%" size="mini">
      <!-- <el-table-column prop="id" label="ID"> </el-table-column> -->
      <el-table-column prop="firstname,lastname" label="Name">
        <template slot-scope="scope">
          {{ scope.row.firstname }} {{ scope.row.lastname }}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email"> </el-table-column>
      <!-- 是否启用 -->
      <el-table-column label="Status">
        <template v-slot="{ row }">
          <el-switch
            v-model="row.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="#F56C6C"
            @click.native="editStatus(row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="Operation">
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" @click="editUser(row)"
            >Edit</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑的弹框 -->
    <el-dialog title="Operator Edit" :visible.sync="dialogVisible" width="50%">
      <div>
        <el-form
          :model="editForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="Firstname" prop="firstname">
            <el-input
              type="text"
              v-model="editForm.firstname"
              autocomplete="off"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="Lastname" prop="lastname">
            <el-input
              type="text"
              v-model="editForm.lastname"
              autocomplete="off"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              type="email"
              v-model="editForm.email"
              autocomplete="off"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              type="password"
              v-model="editForm.password"
              autocomplete="off"
              style="width: 500px"
              placeholder="Fill in when you want to reset the passwrod."
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 弹框操作的按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="sureEdit('ruleForm')"
          >OK</el-button
        >
        <el-button @click="dialogVisible = false" size="mini">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listcount, editUser, editstatus } from '@/api/user'
import {
  validateEmail,
  validatefirstname,
  validatelastname
} from '@/utils/validate.js'
export default {
  data() {
    const exp = /^(?![^a-zA-Z]+$)(?!\D+$).{6,16}$/
    var validatpwd = (rule, value, callback) => {
      if (!exp.test(value)) {
        callback(new Error('6~16 chars,mletters&numbers.'))
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      value: true,
      editForm: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      },
      status: '',
      dialogVisible: false, // 弹框显示隐藏
      rules: {
        firstname: [{ validator: validatefirstname, trigger: 'blur' }],
        lastname: [{ validator: validatelastname, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }]
        // password: [{ validator: validatpwd, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    // 列表渲染
    async getlist() {
      const data = await listcount()
      // console.log(data)
      this.tableData = data.reverse()
    },
    editUser(obj) {
      this.dialogVisible = true
      this.editForm.password = ''
      // console.log(obj)
      // 回填
      this.editForm.id = obj.id
      this.editForm.firstname = obj.firstname
      this.editForm.lastname = obj.lastname
      this.editForm.email = obj.email
    },
    // 修改账号
    async sureEdit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await editUser({
            id: this.editForm.id,
            firstname: this.editForm.firstname,
            lastname: this.editForm.lastname,
            email: this.editForm.email,
            password: this.editForm.password
          })
          // console.log(data)
          if (data.status === 200) {
            this.$message({
              message: 'Edit successful',
              type: 'success'
            })
            this.dialogVisible = false
            this.getlist()
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
          }
        }
      })
    },
    // 修改状态
    async editStatus(detail) {
      // console.log(detail)
      const data = await editstatus({
        id: detail.id,
        status: detail.status
      })
      // console.log(data)
      if (data.status === 200) {
        this.$message({
          message: 'Status changed successfully',
          type: 'success'
        })
        this.getlist()
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-divider__text.is-left {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}
.tittle {
  margin-bottom: 60px;
}
/deep/.el-table tr th {
  background-color: #f5f7fa;
}
/deep/.el-input {
  width: 100% !important;
}
.el-table--mini {
  font-size: 14px;
}
</style>
